@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;600&family=Roboto:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto; /* Ensure vertical scroll is enabled */
}

* {
  box-sizing: border-box; /* Ensure consistent sizing */
}

.header {
  position: relative;
  width: 100%;
  background: linear-gradient(135deg, #000000 0%, #ffeb3b 111%);
  color: #ffeb3b;
  overflow: hidden;
  text-align: center;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.header-container {
  position: relative;
  z-index: 2;
  max-width: 80%; /* Ensure it takes full width */
  margin: 0 auto;
  padding-top: 2rem;
  padding-left: 1rem; /* Added padding to improve readability */
  padding-right: 1rem; /* Added padding to improve readability */
  animation: fadeIn 1.5s ease-in-out;
}

.header-container::after {
  content: "";
  display: table;
  clear: both;
}

.header .title {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  animation: slideInLeft 1s ease-in-out;
  color: white;
  font-weight: bold;
  animation: fadeInSlideUp 2s ease-in-out forwards; /* Fade in and slide up animation */
  
}

.header .subtitle {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
  color: #ffeb3b;
  animation: fadeInSlideUp 2.5s ease-in-out forwards;
}

.header .animatedSubtitle {
  animation: bounce 2s infinite;
}
.header .description {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;
  color: white;
  animation: fadeInUp 1s ease-in-out;
}
.header .highlight {
  font-weight: bold;
  font-size: 2rem;
  color: #ffffff;
  background: linear-gradient(135deg, #ffeb3b, #ff6f00);
  padding: 1rem 2rem;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  animation: pulseButton 1.5s infinite;
}

.header .highlight:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.header .icon {
  font-size: 2rem;
  margin-left: 1rem;
  animation: spin 2s linear infinite;
}

.highlighted {
  font-weight: bold;
}

.logo {
  height: 40px;
  width: auto;
  margin-left: 10px;
  vertical-align: middle;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.headerBox {
  position: relative;
  width: 80%; /* Make the box smaller by reducing the width */
  max-width: 600px; /* Ensure it doesn't get too large on wider screens */
  background: linear-gradient(0deg, #000000 0%, #ffeb3b 111%);
  color: #ffeb3b;
  overflow: hidden;
  border-radius: 15px;
  text-align: center;
  padding: 1.5rem; /* Slightly reduce padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 2rem auto; /* Center the box and add space from the top */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes fadeInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes backgroundShift {
  0% {
    background: linear-gradient(135deg, #ffeb3b -20%, #ff6f00 100%);
  }
  100% {
    background: linear-gradient(135deg, #ff6f00 -20%, #ffeb3b 100%);
  }
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseButton {
  0% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 111, 0, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(255, 111, 0, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 111, 0, 0.5);
  }
}


/* Responsive Design */
@media (max-width: 600px) {
  .header {
    min-height: auto;
    padding: 1rem;
    margin-top: 80px;
  }
  .header .title {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
  }
  .header .subtitle {
    font-size: 1.5rem;
  }
  .header .description {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 0.75rem;
    padding: 0 0.25rem; /* Adjusted padding for smaller screens */
  }
  .header .highlight {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
  }
  .header .icon {
    font-size: 1.5rem;
  }
}
@media (max-width: 480px) {
  .header .title {
    font-size: 2rem;
  }
  .header .subtitle {
    font-size: 1.25rem;
  }
  .header .description {
    font-size: 0.875rem;
    line-height: 1.3;
    margin-bottom: 0.5rem;
    padding: 0 0.25rem; /* Adjusted padding for smaller screens */
  }
  .header .highlight {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
  }
  .header .icon {
    font-size: 1.25rem;
  }
}