@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.skills {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(135deg, #000000 -20%, #ffeb3b 100%);
}
.skills h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #ffeb3b;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
  display: inline-block;
}
.skills h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: #ffeb3b;
  margin: 8px auto 0;
}
.skills .container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.skills .skillList {
  list-style-type: none;
  padding: 0;
  text-align: left;
}
.skills .skillList li {
  background: #282c34;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: white;
  font-family: 'Open Sans', sans-serif;
}
.skills .skillList li:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.skills .skillList strong {
  color: #ffeb3b;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
/* Mobile-specific styles */
@media (max-width: 600px) {
  .skills {
    margin-top: 45px; /* Add margin-top specifically for mobile */
  }
  .skills h2 {
    font-size: 1.5rem;
  }
  
  .skills .skillList li {
    font-size: 1rem;
  }
}