@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(135deg, #000000 -20%, #ffeb3b 100%);
  padding-top: 70px; /* Add padding equal to navbar height */
  margin: 0; /* Ensure no margin */
  min-height: 100vh; /* Ensure body covers full height */
}
/* Adjust specific section styles if needed */
section {
  padding-top: 70px; /* Adjust for the navbar height if sections are affected */
}
.navbar {
  background: linear-gradient(135deg, #000000 -20%, #ffeb3b 100%);
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Reduced shadow */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center; /* Align items vertically */
  height: 120px; /* Explicit height */
  justify-content: center; /* Center content */
  border: none; /* Ensure no border */
}

.navbar.scrolled {
  background-color: #ffeb3b; /* Change background color on scroll */
  color: #000; /* Change text color on scroll */
  transform: translateY(-10px); /* Slight lift on scroll */
}

.navbar.scrolled .navLink {
  color: #000; /* Change text color on scroll */
}

.navbarContainer {
  display: flex;
  justify-content: center; /* Center content */
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  flex-wrap: wrap; /* Allow items to wrap */
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.logoImage {
  border-radius: 10%; /* Rounded edges for the logo */
  height: 90px; /* Adjust the height to fit within the navbar */
  width: 90px; /* Ensure the logo is square for circular rounding */
  margin-top: 0.3cm;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logoImage:hover {
  transform: scale(1.1) rotate(5deg); /* Slight rotation and scale on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.nav {
  display: flex;
  align-items: center;
}

.navList {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping of items */
  justify-content: center; /* Center items */
}

.navItem {
  margin: 0 1rem;
  transform: perspective(1000px) translateZ(10px); /* 3D perspective effect */
  transition: transform 0.3s ease;
}

.navItem:hover {
  transform: perspective(1000px) rotateX(10deg) rotateY(10deg); /* 3D tilt on hover */
}

.navLink {
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
  border-radius: 50px; /* Circular corners */
}

.navLink:hover {
  color: #000;
  background-color: #ffeb3b;
  transform: translateY(-2px) scale(1.1); /* Lift up and scale on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.navLink:active {
  transform: scale(0.95); /* Slightly shrink on click */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow on click */
  background-color: #fdd835; /* Slightly darker yellow on click */
  font-weight: bold; /* Make font bold on click */
}

@media (max-width: 768px) {
  .navbar {
    height: auto; /* Allow height to adjust for content */
  }
  .navbarContainer {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center; /* Center align items */
    margin-bottom: 1rem; /* Add more space below the nav link */
  }
  .navList {
    flex-direction: row; /* Ensure nav items remain in a row */
    width: 100%; /* Make nav items take full width */
    align-items: center; /* Center align nav items */
    flex-wrap: wrap; /* Allow wrapping of items */
    justify-content: center; /* Center items */
  }
  .navItem {
    margin: 0.5rem; /* Adjust margin for nav items */
  }
  .navLink {
    width: auto; /* Allow nav links to adjust width */
    text-align: center; /* Center align text */
    
  }
}
