@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html, body {
  top: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.general {
  background: linear-gradient(135deg, #000000 -20%, #ffeb3b 100%);
  padding: 60px 20px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
  position: relative; /* Ensure z-index is effective */
  z-index: 2; /* Ensure this is above any background elements */
  color: #ffffff; /* Ensure text color is white for contrast */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative; /* Ensure z-index is effective */
  z-index: 2; /* Ensure this is above any background elements */
  padding-bottom: 2rem;
  padding-top: 2rem;
}

/* Class for italic text */
.italic {
  font-style: italic;
  display: inline-block; /* Ensure the border appears only under the text */
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #ffeb3b;
  transform: translateY(20px);
  animation: fadeInUp 1s 0.2s forwards;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
}

h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: #ffeb3b;
  margin: 8px auto 0;
}

.section {
  margin-bottom: 2em;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
  position: relative; /* Ensure z-index is effective */
  z-index: 2; /* Ensure this is above any background elements */
}

h3 {
  font-size: 1.8rem;
  color: #ffeb3b;
  margin-bottom: 0.5em;
  font-family: 'Montserrat', sans-serif;
}

p {
  font-size: 1.1rem;
  color: #ffffff; /* Ensure text color is white for contrast */
  margin-bottom: 0.5em;
  font-family: 'Open Sans', sans-serif;
}

.section:nth-child(1) {
  animation-delay: 0.4s;
}

.section:nth-child(2) {
  animation-delay: 0.6s;
}

.section:nth-child(3) {
  animation-delay: 0.8s;
}

.section:nth-child(4) {
  animation-delay: 1s;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
  .general {
    margin-top: 45px; /* Adjust this value as needed to move the section down */
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}